import { template as template_b43b2f43d0c349e29fbe933e86da38e9 } from "@ember/template-compiler";
import i18n from "discourse-common/helpers/i18n";
import SectionLink from "../section-link";
const SidebarCommonAllCategoriesSectionLink = template_b43b2f43d0c349e29fbe933e86da38e9(`
  <SectionLink
    @linkName="all-categories"
    @content={{i18n "sidebar.all_categories"}}
    @route="discovery.categories"
    @prefixType="icon"
    @prefixValue="sidebar.all_categories"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarCommonAllCategoriesSectionLink;
