import { template as template_732aa7e1832d452d8c156d1b2bc050aa } from "@ember/template-compiler";
const FKControlMenuContainer = template_732aa7e1832d452d8c156d1b2bc050aa(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
