import { template as template_4bda516346424aeeb86a34bffbb95faf } from "@ember/template-compiler";
const FKText = template_4bda516346424aeeb86a34bffbb95faf(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
